body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px !important; 
}

.bg-gray {
  background-color: #F9F9F9;
}

.heading-green {
  color: #02535A;
}

.heading-warning {
  color: #FFFFFF;
  background-color: #E26943;
}

.body-background-warning {
  background-color: #F5CDC1;
}

.heading-primary {
  color: #FFFFFF;
  background-color: #507BB3;
}

.body-background-primary {
  background-color: #C2D1E4;
}

.heading-with-bottom-border {
  border-bottom: 15px solid #EEEEEE;
}

.header-with-padding {
  margin-top: 46px !important;
}

.no-padding .col {
  padding: 0 !important;
  margin: 0 !important;
}

.main-page img {
  width: 500px;
}

.table-photo {
  width: 408px !important;
}

.faq-border {
  border-top: 3px solid #E5E5E5;
}

ul li {
  margin: 0; 
  padding: 0;
  line-height: 1.1;
  text-align: left;
}

ul {
  margin-top: 8px !important;
}

.text-end h2 {
  margin-right: 15px;
  color: #555555;
}

.lighter-container .smooth-dnd-container {
  background-color: #E4EFFE;
  padding-top: 1px;
  border-radius: 8px;
}

.darker-container .smooth-dnd-container {
  background-color: #507BB3;
  padding-top: 1px;
  border-radius: 8px;
}

.smooth-dnd-container {
  min-height: 90% !important;
}

.smooth-dnd-container .smooth-dnd-draggable-wrapper {
  background-color: #FFFFFF;
  width: 85%;
  margin: 0 auto;
  margin-top: 12px;
  border: 1px solid #BBBBBB;
  font-weight: bold;
}

.opacity-ghost {
  transition: all .18s ease;
  opacity: 0.8;
  cursor: ns-resize;
  /* transform: rotateZ(5deg); */
  background-color: cornflowerblue;
  box-shadow: 3px 3px 10px 3px rgba(0,0,0,0.3);
  cursor: ns-resize;
}

.opacity-ghost-drop {
  opacity: 1;
  /* transform: rotateZ(0deg); */
  background-color: white;
  box-shadow: 3px 3px 10px 3px rgba(0,0,0,0.0);
}

.darker-container h4, .lighter-container h4 {
  background-color: #FFFFFF;
}

.darker-container, .lighter-container {
  text-align: left;
  width: 250px;
}

.lighter-container {
  margin-top: 2px;
}

.arrow-icon {
  font-size: 80px;
  align-self: center;
  margin-left: 50px;
  margin-right: 50px;
}

.activity h4 {
  margin-top: 3rem;
}

.activity textarea {
  background-color: #FAFAFA;
  border: none;
}

.header-nav {
  margin-bottom: 5rem;
}

.header-nav button {
  background-color: #507BB3;
  margin-right: 2rem;
  padding-top: 15px;
  padding-bottom: 15px;
  border: 1px solid #507BB3;
  font-size: 20px;
  flex: auto;
  min-width: 15rem;
}

.header-nav button:hover, .header-selected-button {
  box-shadow: rgba(50, 50, 93, 0.25) 3px 3px 0px 0px inset, rgba(50, 50, 0, 0) 5px 5px 0px 0px inset;
  background-color: #FFFFFF !important;
  color: #507BB3 !important;
  border: 1px solid #507BB3 !important;
}

.header-nav button:hover a {
  color: #507BB3 !important;
}

.header-nav a {
  color: #FFFFFF;
  text-decoration: none;
}

.header-link a:hover {
  background-color: #FFFFFF;
  color: #507BB3;
}

.img-transparent {
  background: transparent;
}

.limit-opacity {
  opacity: 0.15;
}

.header-selected-button a {
  color: #507BB3 !important;
}

.header-nav img {
  margin-right: 2rem;
  width: 150px;
}

.textarea-row textarea {
  margin-left: 15px;
}

.btn {
  border-radius: 0;
}

@media screen {
  .content-start {
    margin-top: 130px !important;
  }
}

/* print settings */
@media all {
  .page-break {
    display: none;
  }

  .textarea-row {
    margin-top: 10rem !important;
  }
}

@media print {
  .hide-from-print {
    display: none !important;
  }
  
  p, div {
    font-size: 14px;
  }

  .lighter-container, .darker-container {
    background: none !important;
  }

  .darker-container {
    margin-top: 25px;
  }

  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }

  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }

  .textarea-row {
    padding-top: 6rem !important;
  }
}

@page {
  size: auto;
  margin: 20mm;
}

.print-button, .extra-padding {
  margin-top: 5rem !important;
  margin-bottom: 5rem;
}

.draggable-item {
  font-size: 0.8em;
}

.smooth-dnd-container .smooth-dnd-draggable-wrapper {
  padding: 5px;
}

.default-activity-item {
  margin-left: 35px;
  margin-top: 25px;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 16px;
}

.banner {
  background-color: #e3ecfa;
  height: 115px;
}

.logo {
  width: 235px !important;
  background-color: #F9F9F9 !important;
}

.logo:hover {
  background-color: #F9F9F9 !important;
}

footer {
  background-color: #EEF4F3;
  padding-top: 2rem;
  padding-bottom: 1rem;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 160px;
}

html {
  position: relative;
  min-height: 100%;
  padding-bottom:160px;
}

body {
  margin-bottom: 160px;
}

footer p { 
  white-space: nowrap;
  font-size: 18px;
}

.email-link {
  color: #85C0C8;
}

.header-link button {
  width: 18rem;
  height: 6rem;
}

#sb1, #sb2, #sb3, #sb4, .space-back {
  margin-left: -12px !important;
}

.nudge-p {
  margin-left: 1.2rem;
}

.draggable-item {
  cursor: pointer;
}

.draggable-item {
  margin-left: 5px;
}